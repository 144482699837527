@import url("https://rsms.me/inter/inter.css"); 
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'LINESeedKR-Bd';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Bd.woff2') format('woff2');
}
@font-face {
  font-family: 'LINESeedKR-Th';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Th.woff2') format('woff2');
}
@font-face {
  font-family: 'LINESeedKR-Rg';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/LINESeedKR-Rg.woff2') format('woff2');
}

* { box-sizing: border-box; } 
ul,li,p,h3,h4,h2 { list-style: none; padding:0; margin:0; } 
html,body,#root,.scroll { width: 100%; height: 100%; margin: 0; padding: 0; user-select: none; } 
.scroll { position: absolute; top: 0; left: 0; overflow-y: auto; scroll-snap-type: y proximity; } 
.scroll > div { scroll-snap-align: start; } 
a { text-decoration: none; } 
ul,li { list-style: none; } 

body { font-family:"Poppins","LINESeedKR-Rg", sans-serif; -webkit-font-smoothing: antialiased; background: #121212;} 
.point { color:#E83921 !important; } 
.point_dot {font-size: 6rem; color: #E83921 !important; line-height: 0;} 
.dimmed { position: fixed; width:100%; height:100vh; display: block; background:rgba(0,0,0,0.15); top:0; left:0; z-index: 0; } 
/*
.dimmed_top { position: fixed; width:100%; top:0; left:0; z-index: 5; height:130px; background: linear-gradient(to bottom, rgba(0,0,0,0.3), transparent); } 
.dimmed_bottom { position: fixed; width:100%; bottom:0; left:0; z-index: 5; height:130px; background: linear-gradient(to top, rgba(0,0,0,0.3), transparent); } 
*/

.loading_wrap{top:50%; left:50%; transform: translate(-50%, -50%); position: absolute; text-align: center;}
.loading_txt h3{font-size:1.2rem; color:#fff; font-weight: 100;}

@media only screen and (max-width: 760px) { 
  .point_dot {font-size: 3.5rem;} 
}

/*마우스*/
@-webkit-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@-moz-keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
@keyframes ani-mouse {
	0% {
	opacity: 1;
	top: 29%;
	}
	15% {
	opacity: 1;
	top: 50%;
	}
	50% {
	opacity: 0;
	top: 50%;
	}
	100% {
	opacity: 0;
	top: 29%;
	}
}
 .scroll-btn {
	display: block;
	position: fixed;
  bottom:3%;
	right: 4.5%;
	text-align: center;
  opacity:0.5;
}
.scroll-btn.loading{
  top:50%;
	left: 50%;
  transform: translate(-50%, -50%);
	text-align: center;
  opacity:1;
}
.scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: normal;
	color: #7f8c8d;
	color: #ffffff;
	letter-spacing: 2px;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *.active {
	color: #ffffff;
}
.scroll-btn > *:hover,
.scroll-btn > *:focus,
.scroll-btn > *:active,
.scroll-btn > *.active {
	opacity: 0.8;
	filter: alpha(opacity=80);
}
.scroll-btn .mouse {
	position: relative;
	display: block;
	width: 35px;
	height: 55px;
	margin: 0 auto 5px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 3px solid white;
	border-radius: 23px;
}
.scroll-btn .mouse > * {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: white;
	border-radius: 50%;
	-webkit-animation: ani-mouse 2.5s linear infinite;
	-moz-animation: ani-mouse 2.5s linear infinite;
	animation: ani-mouse 2.5s linear infinite;
}
.txt_dec{font-size:0.7rem; letter-spacing: 0;}

@media only screen and (max-width: 760px) { 
  .scroll-btn .mouse {
    width: 30px;
    height: 45px;
    border: 2px solid white;
  }
}

/*로딩*/
.loading_bg{width:100%; height:100vh; display: block; background: #121212; text-align: center; opacity:0.2; z-index: -1;}
.l_obj{display: inline-block; margin:0 auto; text-align: center; position: absolute; height:100vh; width:auto; z-index: 1;}

/*.container { }*/ 
.container .logo { position:fixed; top:7%; left:50%; transform: translate(-50%, -50%); z-index:10; width:100px; } 
.container .logo img { width:100%; } 
.container .btn_contact { position: absolute; top:3%; left:2%; font-weight: 800; font-size:25px; letter-spacing: -0.05em; z-index:5; } 
.container .btn_contact a { color:#fff; } 
.container .copyright { position:absolute; bottom:3%; left:2.5%; font-weight: 400; font-size:11px; color:#fff; } 
.container .top_link_left{position: absolute; left: 1.5%; top: 2.8%; color: #fff; font-size: 1.7rem; font-weight: bold; letter-spacing: -0.03em;}
.container .top_link_right{position: fixed; right: 9%; top: 3%; color: #fff; font-size: 2rem; font-weight: 300; letter-spacing: -0.03em; z-index: 5;}
.container .top_link_right .menu{text-align: right;padding:0 0 0.5rem 0; cursor: pointer; color:#fff;}
.container .top_link_right .menu a{color:#fff;}

.nav-trigger{width: 6vw; height: 6vw; position: fixed;
  background: 0 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.nav-trigger__circle {
  transition: transform .3s,opacity .3s;
  will-change: transform,opacity;
}
.nav-trigger__circle{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid hsla(0,0%,100%,.25);
  border-radius: 50%;
  transform-origin: center center;
}
.nav-trigger__lines {
  width: 2vw;
  transition: transform .3s;
  will-change: transform;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 0;
}
.nav-trigger__lines .line:first-of-type {
  transform: translateY(-0.3vw);
  transition: transform .3s,opacity .3s;
    will-change: transform,opacity;
    transform-origin: right center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
}
.nav-trigger__lines .line:last-of-type {
  transform: translateY(0.3vw);
  transition: transform .3s,opacity .3s;
    will-change: transform,opacity;
    transform-origin: right center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
}
.nav-trigger:hover .nav-trigger__lines .line:first-of-type {transform: translateY(-0.6vw); opacity:0.6;}
.nav-trigger:hover .nav-trigger__lines .line:last-of-type {transform: translateY(0.6vw); opacity:0.6;}
.nav-trigger:hover .nav-trigger__circle{opacity: 0; transform: scale(1.2);}

.nav-trigger-close{width: 6vw; height: 6vw; position: fixed;
  top: 2%;
  right: 1.5%;
  background: 0 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.nav_close{width:50px; margin:0 auto;}
.nav_close img{width:100%; opacity:.5; transition: transform 1s ease-out}
.nav-trigger-close:hover .nav_close img{opacity:1;}

.bg_menu_on{width:100%; display: none; background:rgba(0,0,0,1); top:0; left:0; height:100vh; position: fixed; z-index: 1; }
.bg_menu_on.open{display: block;}
.bg_menu_on .gnb_wrap{position:absolute; top:50%; left:50%; transform: translate(-50%,-50%);}
.bg_menu_on .gnb_wrap .menu_wrap{display: flex; flex-direction: row; gap:0 2em; z-index: 1;}
.bg_menu_on .gnb_wrap .menu_wrap .item a{font-size:6rem; color:#fff; letter-spacing: -0.05em; font-weight: 200;}
.bg_menu_on .gnb_wrap .menu_wrap .item a:hover{color:#E83921;}
.bg_menu_on .gnb_wrap .sub_menu_wrap{display: flex; flex-direction: row; gap:0 1em; z-index: 1; margin-top:1.7rem;}
.bg_menu_on .gnb_wrap .sub_menu_wrap a{color:#fff;}
.bg_menu_on .gnb_wrap .sub_menu_wrap .item a{font-size:1.5rem; color:#999; letter-spacing: -0.05em; font-weight: 400;}
.sub_menu_wrap{display: flex; flex-direction: row;}

@media only screen and (max-width: 1920px) { 
  .bg_menu_on .gnb_wrap .menu_wrap .item a{font-size:4rem; }
}
@media only screen and (max-width: 760px) { 
  .container .top_link_right .menu{display: none;}
  .container .top_link_right{top:2.5%; right:12%;}
  .nav-trigger__circle{border:0;}
  .nav-trigger__lines{width:7vw;}
  .nav-trigger__lines .line:first-of-type{transform: translateY(-1vw);}
  .nav-trigger__lines .line:last-of-type{transform: translateY(1vw);}
  .nav_close{width:35px;}
  .nav-trigger-close{width: 12vw; height: 12vw; right: 2.5%;}
  .bg_menu_on .menu_wrap .item a{font-size: 2rem !important;}
  .bg_menu_on .gnb_wrap{left: 25%;}
  .bg_menu_on .gnb_wrap .menu_wrap{flex-direction: column; gap:0.6em 0; text-align: left;} 
  .bg_menu_on .gnb_wrap .menu_wrap .item a{font-size:2.2rem !important; color:#fff; font-weight: 400;}
  .bg_menu_on .gnb_wrap .sub_menu_wrap{flex-direction: column; gap:0em 0; text-align: left; margin-top:3rem;}
  .bg_menu_on .gnb_wrap .sub_menu_wrap .item a{font-size:1.2rem !important;}
  .scroll-btn{left:50%; bottom:0%; transform: translate(-50%, -20%);}
}

.info { position: fixed; display: inline-block; text-align: left; bottom: 12%; right:4%; z-index: -1; } 
.info .scene_title { 
  width: auto;
  display: inline-block;
  font-size: 1.3rem;
  color: #fff;
  opacity: 1;
  line-height: 1;
  vertical-align: middle;
  letter-spacing: 0;
  font-weight: 500;
 /*transform: translate(-50%, -50%); */
 } 
.info .default {font-size: 1.2rem; color: #fff; opacity: 0.3; padding: 0 0 0 10px; } 

.button { 
    cursor: pointer; 
    position: relative; 
    display: flex; 
    justify-content: flex-start; 
    align-items: center; 
 } 

.btn_main_sc { margin:20px 0 0 0; } 
.button__text { margin-right: 1vw; color:#fff; font-size:1.3em; font-weight: 200; letter-spacing: -0.03em; opacity: 1; transition: transform .3s ease-out,opacity .3s ease-out; } 
.button__text.company{font-size:1.5rem; color:#999;}
.button__circle { position: relative; display: block; box-sizing: border-box; width:7vw; height: 7vw; } 
.button:before { transition: transform .3s ease-out,opacity .3s ease-out; will-change: transform,opacity; } 
.button svg { transition: transform .3s ease-out; will-change: transform; } 
.button__circle svg { position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 28%; opacity:0.8; } 
.button__circle svg path { fill: #fff; } 
.button__circle:before { content: ""; display: block; width: 100%; height: 100%; border-radius: 50%; border: 1px solid hsla(0,0%,100%,.25); transition: transform .3s ease-out,opacity .3s ease-out; will-change: transform,opacity; } 
.button__circle:hover:before { 
 transform: scale(1.1); /* 크기를 1.2배로 늘립니다. */
 opacity: 0; /* 투명도를 0으로 설정하여 완전히 투명하게 만듭니다. */
 } 
.button__circle:hover svg { 
 transform: translate(-50%, -50%) translateX(5%); opacity:1; /* 5%만큼 오른쪽으로 이동. 원하는 만큼 조정할 수 있습니다. */
 } 

.scene1 .scene1_1 { position: relative; } 
.scene1 .scene1_1 .first_text { width:auto; height:100%; margin:0 auto; } 
.scene1 .scene1_1 .first_text img { position:relative; width:100%; height:100vh; display: inline-block; margin:0 auto; opacity: 1;} 
.scene1 .scene1_2 { width:100%; position: relative; } 

.scene1 h3.second_text { mix-blend-mode: exclusion; color: #fff; width:80%; margin:80% auto 0%; font-size: 3.5em; font-weight: 300; line-height: 1.5; letter-spacing: -0.06em; word-break: initial; position: relative; text-align: justify; vertical-align: middle; } 
.scene1 h3.second_text2 { border:1px blue solid; color: #fff; font-size: 5em; text-align: justify; font-weight: 550; line-height: 1.3; letter-spacing: -0.08em; word-break: initial; right:0; bottom:0; position: relative; } 
.scene1 h3.second_text .title_head{font-size: 4em; letter-spacing: -0.06em; font-weight: 700; line-height: 1; padding:0 0 0.1em 0;}

@media only screen and (max-width: 1920px) { 
  .scene1 h3.second_text{font-size: 2.5em;}
  .info{bottom:13%;}
}
@media only screen and (max-width: 760px) { 
  .scene1 .scene1_1 .first_text {width:90%;} 
  .button__circle svg{width:55%;}
  .button__text.company{font-size:0.9rem; padding:0 5px 0 0;}
}

.scene2 { position: relative; } 
.scene2 .dot { display: flex; justify-content: space-between; grid-gap: 5vw; gap: 5vw; } 
.scene2 .dot .dot_inner_header { width:30vw; } 
.scene2 .dot .dot_inner_body { width:70vw; color:#fff; margin-top:4vw; } 
.scene2 .dot .dot_inner_body .b_area { padding:50px 0 0 0; } 
.scene2 .dot .dot_inner_body .dot_num { font-size:1.6em; font-weight: 300; padding: 0 0 5px 5px; margin:0; } 
.scene2 .dot .dot_inner_body .dot_title { font-size:4.8em; color:#fff; line-height: 1; letter-spacing: -0.04em; padding:0; margin:0; font-weight: 200; } 
.scene2 .dot .dot_inner_body .dot_desc { width:40vw; word-break: keep-all; font-size:1.3em; line-height: 1.5; letter-spacing: -0.03em; font-weight: 300; padding:0; margin:10px 0 0 0; } 
.scene2 .desc { font-size: 2.5em; font-weight: 300; line-height: 1.6; letter-spacing: -0.08em; word-break: initial; position: relative; text-align: left; } 
.scene2 .diagram { width:80%; position: relative; margin:0 auto; top:-150px; } 
.scene2 .diagram .flag_plan { position:absolute; top:200px; right:542px; } 

.scene7 .ending .desc1 { color:#fff; font-size:2.2em; letter-spacing: -0.03em; font-weight: 200; line-height: 1.5; } 
.scene7 .ending .desc2 { padding:30px 0; } 
.scene7 .ending .desc2 a { font-size:1.7em; border-bottom:1px #fff solid; color:#fff; font-weight: 300; } 
.scene7 .ending .address { color:#fff; font-size:1.2em; font-weight: 100; } 
.scene7 .ending .copyright { color:#fff; font-size:.8em; padding:20px 0 0 0; font-weight: 100; } 
.scene7 .ending .end_btn_wrap{margin:3.3rem 0;}
.scene7 .ending .end_link{border-bottom:1px #fff solid; line-height: 1; font-size:2rem; color:#fff; font-weight: 300;}

.caption { position: fixed; top: 0; right: 0; margin: 80px; color: white; font-size: 8em; font-weight: 100; line-height: 1em; font-variant-numeric: tabular-nums; -webkit-font-smoothing: auto; opacity:0 } 
.last_dot { color:#E83921; } 
.dot { width:100%; position: sticky; top: 0px; max-width: 100%; padding: 0; padding: 7rem 5rem; } 
.dot img { width:100%; } 
.dot > h1 { -webkit-font-smoothing: auto; color: #999; font-size: 2em; font-weight: 800; line-height: 1em; margin: 0; margin-bottom: 0.25em; } 
.dot .title { color: #fff; font-weight: 600; line-height: 1.2; margin:0; padding:0; font-size: 3em; letter-spacing: -0.05em; word-break: keep-all; position: relative; } 
.dot .title_head { background: linear-gradient(30deg, #ec62e3, #ffcc70); font-size:10rem; letter-spacing: -0.05em; line-height: 1; -webkit-background-clip: text; margin:0; font-weight: 600; -webkit-text-fill-color: transparent; } 
.dot .title_head.main{width:50%;}
.dot .title_head.main.ending{margin:0 0 3rem 0;}
.dot .desc { width:50vw; color: #fff; font-weight: normal; word-break: keep-all; line-height: 1.5; font-size: 2.2em; letter-spacing: -0.05em; word-break: keep-all; padding:10px 0; margin:0; } 

@media only screen and (max-width: 1920px) {
  .container .logo { top:6%; width:100px; position: fixed;} 
  .dot .title_head{font-size:7rem;}
  .dot .desc{font-size:1.8em; line-height: 1.3;}
  .scene7 .ending .address{font-size: 0.9em;}
  .scene7 .ending .end_link{font-size: 1.5em;}
}
@media only screen and (max-width: 760px) { 
  .container .logo { top:2%; transform: translate(-50%, -50%); z-index:10; width:70px; position: fixed;} 
  .info { text-align: center; bottom: 0%; left:0%; right:auto; padding:20px; z-index:1; } 
  .scene7 .ending .address{font-size: 0.8em;}
  .scene7 .ending .end_link{font-size: 1.2em;}

  .scene1 .scene1_2 { padding:16px; } 
  .scene1 h3.second_text { font-size: 1.5em; line-height: 1.3; width:90vw;} 

  .dot { padding:16px; top:10%;} 
  .dot .title_head { width:100%; font-size:3em } 
  .dot .desc { width:100%; line-height: 1.4; font-size:1.2em; } 
  .dot .title_head{padding:0;}
  .button__text{font-size:0.8em;}
  .scene7 .ending .desc1{font-size:1em;}
 } 



@media only screen and (max-width: 1920px) { 
 .scene1 .first_text { width:auto; max-width: 100%; height:100vh; margin:0 auto; } 
 .scene1 .first_text img { margin:0 auto; } 
 } 


/*project Scene*/
.project_scene { width: 100vw; height: 100vh; display: flex; align-items: center; justify-content: flex-end; flex-direction: column; position: relative; top: 0; bottom: 0; left: 0; } 
.project_scene .column_wrap { display: flex; flex-direction: column; justify-content: space-between; z-index: 1; } 
.project_scene .column_wrap .column_left { left:0; } 
.project_scene .column_wrap .column { position: absolute; width: 4rem; height: 100%; top: 0; display: flex; justify-content: space-between; padding: 0.9rem 0.24rem 0.24rem; } 
.project_scene .column_wrap .column .first_wrap { width: 1.5rem; height: 100%; display: flex; flex-direction: column; justify-content: space-between; } 
.project_scene .column_wrap .column .first_wrap ul li { line-height: 1.4; font-size: 1.4rem; color:#888; word-break:keep-all; } 


/*footer*/
.footer { width:95vw; margin:30rem auto 0; position: relative; } 
.footer .linkpath { font-size:14px; color:#fff; } 
.footer .ending .desc1 { color:#fff; text-align: center; padding-top:1rem; font-size:1.5em; width:30vw; word-break: keep-all; margin:0 auto; letter-spacing: -0.03em; font-weight: 200; line-height: 1.5; } 
.footer .ending .desc2 { padding:30px 0; } 
.footer .ending .desc2 a { font-size:1.7em; border-bottom:1px #fff solid; color:#fff; font-weight: 300; } 
.footer .ending .address { color:#fff; font-size:.8em; font-weight: 100; } 
.footer .ending .copyright { color:#fff; font-size:.8em; padding:20px 0 0 0; font-weight: 100; } 
.footer_botttom_wrap { display: flex; padding:20rem 0 5rem; color:#fff; justify-content: space-between; align-items: flex-end; } 
.footer_botttom_wrap .fb_container1 { flex:1; justify-content: flex-start; flex-direction: column; align-items: flex-end; } 
.footer_botttom_wrap .fb_container1 a{color:#fff;}
.footer_botttom_wrap .fb_container1 a:hover{color:#E83921;} 
.footer_botttom_wrap .fb_container1 .text{font-size:1.8rem; font-weight: 600; padding:0 0 10px 0; letter-spacing: -0.03em;}
.footer_botttom_wrap .fb_container2 { flex:1; text-align: center; flex-direction: row; position: relative; align-items: flex-end; } 
.footer_botttom_wrap .fb_container2 .text{font-size:1.2rem; font-weight: 300;}
.footer_botttom_wrap .fb_container3 { flex:1; text-align: right; justify-content: flex-end; flex-direction: column; align-items: flex-end; } 
.footer_botttom_wrap .fb_container3 a{color:#fff; transition: transform .3s ease-out; will-change: color;}
.footer_botttom_wrap .fb_container3 a:hover{color:#E83921;}
.footer_botttom_wrap .fb_container3 .text{font-size:1.8rem; font-weight: 600; padding:0 0 10px 0; letter-spacing: -0.03em;}
.footer .title_head { display: inline-block; font-size: 9rem; width:100%; text-align: center; font-weight: 500; color: #fff; margin: 2rem auto; letter-spacing: -0.05em; line-height: 0.8; } 
/* char 클래스에 애니메이션 효과 적용 */
.title_head .char { 
 display: inline-block; 
 font-size: 10rem; 
 animation: cute-bounce 0.8s infinite alternate ease-in-out; 
 } 
/* 각 글자에 대한 별도의 지연 시간을 주어 한 글자씩 애니메이션을 시작하도록 합니다 */
.title_head .char:nth-child(1) { 
 animation-delay: 0s; 
 } 
.title_head .char:nth-child(2) { 
 animation-delay: 0.1s; 
 } 
.title_head .char:nth-child(3) { 
 animation-delay: 0.2s; 
 } 
.title_head .char:nth-child(4) { 
 animation-delay: 0.3s; 
 } 
.title_head .char:nth-child(5) { 
 animation-delay: 0.4s; 
 } 
.title_head .char:nth-child(6) { 
 animation-delay: 0.5s; 
 } 
.title_head .char:nth-child(7) { 
 animation-delay: 0.6s; 
 } 
.title_head .char:nth-child(8) { 
 animation-delay: 0.7s; 
 } 
.title_head .char:nth-child(9) { 
 animation-delay: 0.8s; 
 } 
.title_head .char:nth-child(10) { 
 animation-delay: 0.9s; 
 } 
/* 나머지 글자들도 같은 방식으로 지연 시간을 조정합니다 */

@keyframes cute-bounce { 
    0% { 
        transform: translateY(0); 
    } 
    100% { 
        transform: translateY(-15px); 
    } 
 } 

@media only screen and (max-width: 1000px) { 
 .caption { 
 font-size: 4em; 
 } 
 } 

@media only screen and (max-width: 800px) { 
 .caption { 
 font-size: 3em; 
 } 
 .dot > h1 { 
 font-size: 3em; 
 } 
 } 

@media only screen and (max-width: 700px) { 
 .caption { 
 font-size: 2em; 
 } 
 .dot > h1 { 
 font-size: 3em; 
 } 
 } 

@media only screen and (max-width: 600px) { 
 .caption { 
 font-size: 1em; 
 } 
 .dot > h1 { 
 font-size: 3em; 
 } 
 } 


 /***subpage***/
.bg_sub_space > div{position: fixed;}
.sub_title_head{position: absolute; top: 50%; left: 50%; transform: translate3d(-50%,-50%,0);}
.sub_title_head h1{background: linear-gradient(30deg, #ec62e3, #ffcc70); text-align: center; font-size:13rem; line-height: 1em; letter-spacing: -0.05em; font-weight: 600; padding:0 10px;  -webkit-background-clip: text; margin:0; -webkit-text-fill-color: transparent;}
.sub_title_head .title_head{overflow: hidden;}
.sub_title_head .sub_title{color:#fff; font-size:3rem;}
.desc_head{font-size: 2rem; color: #fff; letter-spacing: -0.05em; line-height:1.3; word-break: keep-all; padding:1.5rem 0 0 0; text-align: center; font-weight: 300;}
.alert{
  position: absolute;
  top: -6px;
  right: -25%;
  border-radius: 38px;
  background: rgba(232,57,33,0.86);
  color: #fff;
  box-shadow: 1px 9.9px 16px 0 rgba(232,57,33,0.4);
  border: 1px solid #E83921;
  line-height: 1;
  padding: 0.5rem 1.3rem 0.6rem;
  font-size: 1.6rem;
  letter-spacing: -1.94px;
}

@media (max-width: 1920px) {
  .sub_title_head h1{font-size:9rem;}
  .desc_head{font-size: 2rem;}
}

/*subpage*/
.sub_container{width:100%; display: block;}
.sub_container .contents{position:relative;}
.sub_container .wrap_grid.start{padding-top:0rem !important;}
.sub_container .wrap_grid{width: 65vw; padding-top:20rem;  margin: 0 auto auto; position: relative; z-index: 0;}
.sub_container .wrap_grid .about .sub_title{color:#fff; font-size:3rem;}
.sub_container .wrap_grid .about .title{color:#fff; line-height: 1; font-size: 8em; padding:8rem 0; letter-spacing: -0.08em; display: inline-block; position:relative;}
.sub_container .wrap_grid .about .title span{position: relative; display: inline-block;}
.sub_container .wrap_grid .about .desc{letter-spacing:-0.03em; color:#fff; font-size:2.4em; line-height: 1.4; word-break: keep-all;}

.concept_img img{width:100%;}

.canvas_container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Canvas를 화면 뒤로 보냅니다. */
  background-size: cover;
  background-position: center;
}

/*test*/
.image-container {
    display: none;
    opacity: 0;
    transition: opacity 0.5s;
  }

.type2 .wrap_grid{margin: 3.5rem auto auto; display: flex; justify-content: space-between; align-items: flex-start; color:#fff; font-size:2.4em;}
.type2 .wrap_grid.start{margin:0 auto auto !important}
.type2 .title{width:fit-content; flex:1; font-size: 1.7rem; letter-spacing: -0.03em; font-weight: normal;}
.type2 .desc{flex:1; line-height: 1.4; margin: 0rem auto auto; justify-content: space-between; align-items: flex-start; color:#fff; font-size:0.8em;}
.type2 .desc .desc_word h3{font-size:3.6rem; letter-spacing: -0.03em; margin:0; padding:0; font-weight: bold; line-height:1;}
.type2 .desc .desc_word .plain{font-size: 2rem; font-weight: 300; letter-spacing: -0.04em; line-height: 1.4; padding:0; margin:0; word-break: keep-all;}
.type2 .desc .desc_word .list_txt{padding:30px 0;}
.type2 .desc .desc_word .list_txt span{padding:5px 0; font-weight: 500; font-size:1.5rem; display: block; color:#999;}
.type2 .contents_img{display: block;}
.type2 .desc_cross{display: flex; flex:1; flex-direction: column;}
.type2 .desc_cross .cp_tit{font-size:1.2rem; color:#ccc; padding: 0 0 0.3rem 0; font-weight: normal;}
.type2 .desc_cross .cp_text{font-size:1.7rem; color:#fff; padding: 0 0 4rem 0; font-weight: normal;}
.type2 .desc .num{border:1px #E83921 solid; display: inline-block; line-height: 3; color:#E83921; padding: 0 42px; font-size: 2rem; vertical-align: middle; text-align: center; border-radius: 50%;}
.type2 .desc .cate_name{ color:#E83921; font-weight: 800; font-size:3rem; letter-spacing: -0.05em;  }
.type2 .desc .slogan_txt{font-size: 5rem; font-weight: 600; line-height: 1; letter-spacing: -0.05em; padding:0 0 3rem 0;}
.type2 .desc .slogan_txt span{display: inline-block;}
.type2 .desc .slogan_txt .sub_title{font-size:2rem; color:#fff; letter-spacing: 0; font-weight: 300; padding:30px 0 0 0;}
.poster_type1{width:100%;}
.poster_type1 img{width: 70%;}
.poster_type2{padding:10%; position:relative;}

@media (max-width: 1920px) { 
  .type2 .desc .desc_word .plain{font-size:1.6rem;}
  .type2 .desc .desc_word h3{font-size:3rem;}
  .type2 .desc .slogan_txt{font-size:4rem;}
  .sub_container .wrap_grid{width:80vw;}
  .sub_container .wrap_grid .about .title{font-size:6em;}
  .sub_container .wrap_grid .about .sub_title{font-size:2rem;}
  .sub_container .wrap_grid .about .desc{font-size:1.8rem; line-height: 1.4;}
  .type2 .desc{font-size:1.6rem;}
  .type2 .desc_cross .cp_text{font-size:1.3rem; padding:0 0 2rem 0;}
  .type2 .desc_cross .cp_tit{padding:0;}
}

@media (max-width: 1200px) {   
  .sub_container .wrap_grid{width:80vw;}
}

@media (max-width: 768px) { 
  .sub_container .wrap_grid .about .sub_title{font-size:1.5rem;}
  .sub_container .wrap_grid .about .title{font-size:3rem; padding:3rem 0; word-break: keep-all;}
  .sub_container .wrap_grid .about .desc{font-size:1.4rem; word-break: keep-all; line-height: 1.4;}
  .type2 .desc{font-size:1.4rem; word-break: keep-all; line-height: 1.4;}
  .type2 .desc_cross .cp_tit{padding:1.5rem 0 0 0;}
  .type2 .desc_cross .cp_text{font-size:1.2rem; padding:0 0 0rem 0;}
  .type2 .wrap_grid {
    margin:1rem 0 0 0;
    flex-direction: column; 
    align-items: left; 
    text-align: left;
  }
  
  .type2 .title,
  .type2 .desc,
  .type2 .desc_cross {
    font-size:1.3rem;
    flex: 1 0 100%;
  }
}
.cursor-image{width:200px;}
.cursor-image img{width:100%;}

.type3 {margin: 15rem auto auto; grid-auto-rows: 1fr; grid-template-columns: repeat(3,1fr); grid-template-rows: 1fr; grid-gap: 42rem 12rem; display: grid; gap: 5rem 3rem;}
.type3 .type3_item .item_image{width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; -o-object-position: center; object-position: center;}
.type3 .cp_tit{font-size:1.2rem; color:#ccc; padding: 2rem 0 0.5rem 0; font-weight: normal;}
.type3 .cp_text{font-size:1.7rem; color:#fff; padding: 0 0 0rem 0; font-weight: 100;}

@media (max-width: 768px) { 
  .type3 {
      pointer-events: none;
      display: flex;
      grid-gap: 48rem;
      gap: 5rem;
      flex-direction: column;
      margin:5rem auto auto;
  }
}

.type_g2 {margin: 15rem auto auto; grid-auto-rows: 1fr; grid-template-columns: repeat(2,1fr); grid-template-rows: 1fr; grid-gap: 42rem 12rem; display: grid; gap: 5rem 8rem; padding-left:30rem;}
.type_g2.start{margin:0 auto !important;}
.type_g2 .type2_item {overflow: hidden; position: relative; margin:0 0 8rem 0;}
.icon_lock{width: 50px; height: 50px; padding: 12px 15px 12px 15px; background: rgba(0,0,0,0.5); border-radius: 50%; vertical-align: middle; opacity: 0.4; margin:0 0.5rem 0.2rem 0;}
.icon_lock_word{font-size: 0.7rem; color: #fff; padding:18px 0 0 2px; opacity: 0.5;}
.icon_lock img{width:20px !important;}
.type_g2 .type2_item:nth-child(2n){transform: translateY(15rem);}
.type_g2 .type2_item .txt_wrap{position: relative; z-index: 3; padding:50% 6% 10px; margin:0 0 20px 0;}
.type_g2 .type2_item .txt_wrap .wrap_icon{display: flex; flex-direction: row;}

.type_g2 .type2_item .overlay_txt{position: absolute; bottom:0px; z-index: 1; padding:0px; opacity:0; transition: opacity 0.3s ease-in; width:100%;}
.type_g2 .type2_item:hover .overlay_txt{opacity:1;}

.type_g2 .type2_item .type2_item_poster{position: relative; overflow: hidden;}
.type_g2 .type2_item .type2_item_poster img{width:100%;  transition: transform 0.7s ease-in-out; overflow: hidden;}
.type_g2 .type2_item:hover .type2_item_poster img{ transform: scale(1.1);}
.type_g2 .type2_item .dim_txt{display: block; background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); position: absolute; z-index: 0; width: 100%; height: 100%; top: 0;}
.type_g2 .type2_item .item_image{width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; -o-object-position: center; object-position: center;}
.type_g2 .type2_item:hover .icon_lock img{ transform: scale(1) !important;}

.type_g2 .cp_tit2{font-size:1.2rem; color:#ccc; padding: 0rem 0 0.5rem 0; font-weight: normal;}
.type_g2 .cp_text2{font-size:2.1rem; color:#fff; padding: 1rem 0 1rem 0; font-weight: 300; letter-spacing: -0.05em;}
.type_g2 .cp_text2 .btn_link{width: 35px; height: 35px; cursor: pointer; display: inline-block; margin: 6px 0 0 15px; background: #444; text-align: center; border-radius: 50%; padding: 0 9px; line-height: 1; vertical-align: text-top; opacity:0.7; transition: transform 0.7s ease-in-out; will-change: transform;}
.type_g2 .cp_text2 .btn_link:hover{background:#333; opacity:1;}
.type_g2 .cp_text2 .btn_link img{width: 100%; display: inline-block; vertical-align: text-top; padding: 9px 0 0 0;}
.type_g2 .cp_tit2 .tag_cate{border-radius: 0; border:0px #999 solid; margin:10px 10px 0 0; display: inline-block; border-radius: 30px; background:rgba(0,0,0,0.2); color:#fff; opacity:0.8; padding:6px 16px 6px; font-size:0.6em;}
.type_g2 .cp_text2_desc{font-size:1.2rem; color:#fff; padding: 0.5rem 0 0.5rem 0; font-weight: 100; word-break: keep-all;}

.work_menu_head{color:#fff; position: absolute !important; top:-5%; left:0;}
.work_menu_head .work_menu_top{font-size:6rem; padding:0 0 0.5em;}
.work_menu_head .work_menu_sub{font-size:2rem; letter-spacing: -0.05em; padding:0 0 0.4em; color:#999;}

@media (max-width:1920px) {
  .type_g2{padding-left:20rem;}
  .work_menu_head .work_menu_top{font-size:4.5rem;}
  .work_menu_head .work_menu_sub{font-size:1.6rem;}
  .type_g2 .cp_text2_desc{font-size:1rem;}
  .type_g2 .cp_text2{font-size:2rem;}
  .type_g2 .cp_tit2{font-size:1rem;}
  .alert{font-size:1.2rem;}
  .type_g2{gap:5rem;}
}
@media (max-width:1200px) {
  .work_menu_head{display: none;}
  .type_g2{padding-left:0rem;}
}

@media (max-width: 768px) { 
  .work_menu_head{display: block;}
  .type_g2 .cp_text2{font-size:1.7rem;}
  .type_g2 .cp_tit2{font-size:1rem;}
  .type_g2 .cp_text2 .btn_link{vertical-align: baseline;}
  .type_g2 {
      display: flex;
      grid-gap: 8rem;
      gap: 8rem;
      flex-direction: column;
      padding:0 0 0 0;
  }
  .type_g2 .type2_item:first-child{margin:15rem 0 0 0;}
  .type_g2 .type2_item:nth-child(2n){transform: translateY(0rem);}
  .type3 .cp_tit{padding:1rem 0 0.5rem 0; font-size:1rem;}
  .type3 .cp_text{font-size:1.5rem;}
  .work_menu_head{ top:0%; }
  .work_menu_head .work_menu_top {font-size:2rem;}
  .alert {display: none;}
  .work_menu_head .work_menu_sub{font-size:1.2rem;}
  
}

@media (max-width: 1920px) {
  .title_head .char{font-size:7rem;}
  .footer_botttom_wrap .fb_container1 .text{font-size:1.3rem;}
  .footer_botttom_wrap .fb_container3 .text{font-size:1.3rem;}
}
@media (max-width: 768px) {
  .footer{margin:10rem 0 0 0; padding:0 16px;}
  .sub_container .wrap_grid{width:90vw; padding:10rem 0 0 0;}
  .sub_title_head{text-align: center;}
  .sub_title_head h1{font-size:5rem; line-height:1;}
  .desc_head{font-size:1.3em;}
  .title_head .char{font-size:2rem;}
  .footer_botttom_wrap{flex-direction: column; align-items: flex-start;}
  .type2 .desc{margin:0; padding:1rem 0 0 0;}
  .type2 .desc .desc_word h3{font-size:2.5rem;}
  .type2 .desc .desc_word .plain{font-size:1.2rem; text-align: left;}
  .type2 .desc .slogan_txt{font-size:2.5rem; margin:2rem 0 0 0; padding:0 0 2rem 0; text-align: left;}
  .type2 .desc .slogan_txt span.sub_title{padding:12px 0 0 0;}
  .type2 .desc .desc_word .list_txt span{font-size:1rem; text-align: left; padding:1px 0;}
  .poster_type1 img{width:100%;}
  .footer .ending .desc1{width:90vw; font-size:1rem; text-align: left;}
  .footer .title_head{letter-spacing: -0.01em; margin:0; text-align: left;}
  .footer_botttom_wrap{padding:10rem 0 4rem 0;}
  .footer_botttom_wrap .fb_container1 .text{font-size:1rem; padding:0 0 5px 0;}
  .footer_botttom_wrap .fb_container3{display: none;}
  .footer_botttom_wrap .fb_container2 .text{text-align: left; font-size:0.8rem;}
 }

.wrap_anim{width:100%; position: relative;}
.circle {position: absolute; width: 200px; height: 200px; border-radius: 50%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference;}
.circle1 {background-color: #c850c0; animation-name: moveCircle1;}
.circle2 {background-color: #fff; animation-name: moveCircle2;}
.circle3 {background-color: #ffff00; animation-name: moveCircle3;}
.circle4 {background-color: #E83921; animation-name: moveCircle4;
}
/*translate(X, Y);*/
@keyframes moveCircle1 {
  0%, 100% {transform: translate(0, 0);}
  25% {transform: translate(200%, 0);}
  50% {transform: translate(200%, 200%);}
  75% {transform: translate(0, 200%);}
}
@keyframes moveCircle2 {
  0%, 100% {transform: translate(200%, 0);}
  25% {transform: translate(200%, 200%);}
  50% {transform: translate(0, 200%);}
  75% {transform: translate(0, 0);}
}
@keyframes moveCircle3 {
  0%, 100% {transform: translate(0, 200%);}
  25% {transform: translate(0, 0);}
  50% {transform: translate(200%, 0);}
  75% {transform: translate(200%, 200%);}
}
@keyframes moveCircle4 {
  0%, 100% {transform: translate(0, 0);}
  25% {transform: translate(0, 0);}
  50% {transform: translate(0, 0);}
  75% {transform: translate(0, 0);}
}
.Bcircle1 {mix-blend-mode:difference; position: absolute; background-color: #ffff00; opacity:1; width: 200px; height: 200px; border-radius: 50%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference; animation-name: BmoveCircle1;}
.Bcircle2 {position: absolute;  background-color:#E83921; width: 200px; height: 200px; border-radius: 0%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference; animation-name: BmoveCircle2;}
.Bcircle3 {mix-blend-mode:difference; position: absolute; background-color: #fff; opacity:1; width: 200px; height: 200px; border-radius: 50%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference; animation-name: BmoveCircle3;}
@keyframes BmoveCircle1 {
  0%, 100% { transform: translate(50%, 50%); }
  50% { transform: translate(150%, 50%);}
}
@keyframes BmoveCircle2 {
  0% {transform: translate(50%, 50%) rotate(0deg);}
  100% {transform: translate(50%, 50%) rotate(360deg);}
}
@keyframes BmoveCircle3 {
  0%, 100% { transform: translate(50%, 50%); }
  50% { transform: translate(-50%, 50%);}
}
.Ccircle1 {mix-blend-mode:difference; position: absolute; background-color: #ffff00; opacity:1; width: 200px; height: 200px; border-radius: 50%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference; animation-name: CmoveCircle1;}
.Ccircle2 {position: absolute;  background-color:#E83921; width: 200px; height: 200px; border-radius: 50%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference; animation-name: CmoveCircle2;}
.Ccircle3 {mix-blend-mode:difference; position: absolute; background-color: #fff; opacity:1; width: 200px; height: 200px; border-radius: 50%; animation-duration: 4s; animation-timing-function: ease-in-out; animation-iteration-count: infinite; mix-blend-mode: difference; animation-name: CmoveCircle3;}
@keyframes CmoveCircle1 {
  0%, 100% { transform: translate(50%, 50%); }
  50% { transform: translate(150%, 50%);}
}
@keyframes CmoveCircle2 {
  0% {transform: translate(50%, 50%) rotate(0deg);}
  100% {transform: translate(50%, 50%) rotate(360deg);}
}
@keyframes CmoveCircle3 {
  0%, 100% { transform: translate(50%, 50%); }
  50% { transform: translate(-50%, 50%);}
}

@media (max-width: 768px) {  
  .wrap_anim{height:67px;}
  .circle {position: absolute; width: 30px; height: 30px;}
  .Bcircle1, .Bcircle2, .Bcircle3{width:50px; height:50px;}
  .Ccircle1, .Ccircle2, .Ccircle3{width:50px; height:50px;}
}